import React, { useState } from 'react';
import '../scss/FAQ.scss';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

export function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq">
      <h2 className="faq-title">Часто задаваемые вопросы</h2>
      <div className="faq-items">
        {faqData.map((item, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
          >
            <div className="faq-question" onClick={() => toggleAnswer(index)}>
              <h3>{item.question}</h3>
              {activeIndex === index ? <FaChevronUp className="faq-toggle" /> : <FaChevronDown className="faq-toggle" />}
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const faqData = [
  {
    question: 'Какой у вас график работы?',
    answer: 'Мы работаем ежедневно с 10:00 до 22:00.'
  },
  {
    question: 'Как я могу связаться с вами?',
    answer: (
      <>
        Вы можете позвонить нам по телефону <a href="tel:+79657542368">+7-967-979-43-50</a>.
      </>
    )
  }
];
