import React, { useEffect } from 'react';
import { ServiceCard } from './ServiceCard';
import '../scss/Services.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const services = [
  {
    id: 1,
    title: 'Выкуп техники',
    description: 'Быстрый и удобный выкуп вашей техники Apple по выгодной цене. Гарантируем безопасные и прозрачные сделки.',
    icon: '💰',
    details: 'Мы предлагаем конкурентоспособные цены на ваш бывший в употреблении Apple. Наша команда оценит ваше устройство и предложит лучшую цену.',
  },
  {
    id: 2,
    title: 'Продажа техники',
    description: 'Большой ассортимент проверенной и отремонтированной техники Apple. Гарантия качества и надежности.',
    icon: '🛒',
    details: 'У нас вы найдете широкий выбор отремонтированных устройств Apple. Все устройства проходят строгий контроль качества перед продажей.',
  },
  {
    id: 3,
    title: 'Ремонт техники',
    description: 'Качественный ремонт техники Apple с использованием оригинальных запчастей. Быстро и надежно.',
    icon: '🔧',
    details: 'Наши опытные специалисты выполнят ремонт любой сложности. Мы используем только оригинальные запчасти для обеспечения долгого срока службы вашего устройства.',
  },
  {
    id: 4,
    title: 'Выездной ремонт',
    description: 'Удобный выездной ремонт техники Apple на дому или в офисе. Экономьте время и получайте качественные услуги без визита в сервисный центр.',
    icon: '🚗',
    details: 'Наши специалисты приедут к вам и произведут ремонт на месте. Мы привозим все необходимое оборудование и запчасти, чтобы ваша техника была отремонтирована максимально быстро и качественно.',
  }
];

export const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section className="services">
      <div className="services-header" data-aos="fade-up">
        <h1>Наши Услуги</h1>
        <p>Мы предоставляем полный спектр услуг по выкупу, продаже и ремонту техники. Ознакомьтесь с нашими предложениями ниже.</p>
      </div>
      <div className="services-list">
        {services.map((service) => (
          <div key={service.id} data-aos="fade-up" data-aos-delay="100" className="service-item">
            <ServiceCard {...service} />
          </div>
        ))}
      </div>
    </section>
  );
};
