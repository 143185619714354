import React from 'react';
import '../scss/Advantages.scss';
import { FaCheckCircle, FaUserTie, FaMapMarkerAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';

export const Advantages = () => {
    return (
        <motion.section
            className="advantages"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: 'spring', stiffness: 60, delay: 0.2 }}
        >
            <h2 className="section-title">Преимущества</h2>
            <ul className="advantages-list">
                <li className="advantage-item">
                    <FaCheckCircle className="advantage-icon" />
                    <h3>Гарантия качества</h3>
                    <p>Мы предлагаем гарантию на все наши услуги и продукты.</p>
                </li>
                <li className="advantage-item">
                    <FaUserTie className="advantage-icon" />
                    <h3>Профессионализм</h3>
                    <p>Наша команда состоит из сертифицированных специалистов с многолетним опытом.</p>
                </li>
                <li className="advantage-item">
                    <FaMapMarkerAlt className="advantage-icon" />
                    <h3>Удобство</h3>
                    <p>Вы можете легко найти нас в удобном для вас месте.</p>
                </li>
            </ul>
        </motion.section>
    );
};
