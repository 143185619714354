import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import '../scss/SendPhoneNumber.scss';

const phoneModels = [
  'iPhone 15 Pro Max', 'iPhone 15 Pro', 'iPhone 15',
  'iPhone 15 Plus', 'iPhone 14 Pro Max', 'iPhone 14 Pro',
  'iPhone 14', 'iPhone 14 Plus', 'iPhone 13 Pro Max',
  'iPhone 13 Pro', 'iPhone 13', 'iPhone 13 Mini',
  'iPhone 12 Pro Max', 'iPhone 12 Pro', 'iPhone 12',
  'iPhone 12 Mini', 'iPhone 11 Pro Max', 'iPhone 11 Pro',
  'iPhone 11', 'iPhone SE (2nd generation)', 'iPhone SE (3rd generation)',
  'iPhone X', 'iPhone XS', 'iPhone XS Max', 'iPhone XR'
];

export function SendPhoneNumber() {
  const [formData, setFormData] = useState({
    phone: '',
    name: '',
    model: '',
    condition: '',
    description: '',
    serviceType: '',
  });

  const [error, setError] = useState('');
  const [formError, setFormError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData(prevData => ({ ...prevData, [name]: value }));

    if (name === 'phone') {
      const pattern = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
      if (!pattern.test(value)) {
        setError('Пожалуйста, введите действительный номер телефона в формате: +7 (XXX) XXX-XX-XX');
      } else {
        setError('');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Проверка на наличие ошибки перед отправкой данных
    if (error) {
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      console.log("Отправляемые данные:", formData);
      
      // Отправка данных на сервер
      const response = await fetch('https://re-service.pro/api/sendMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
      
      const data = await response.json();
      
      // Проверка статуса ответа
      if (data.status === 'success') {
        alert('Сообщение успешно отправлено!');
        // Сброс формы после успешной отправки
        setFormData({
          phone: '',
          name: '',
          model: '',
          condition: '',
          description: '',
          serviceType: '',
        });
        setError('');
      } else {
        // Обработка ошибки, если статус не "success"
        setFormError(`Ошибка отправки сообщения: ${data.message || 'Неизвестная ошибка'}`);
      }
    } catch (err) {
      // Обработка ошибок, связанных с запросом
      setFormError(`Ошибка при отправке сообщения: ${err.message || 'Неизвестная ошибка'}`);
    } finally {
      // Сброс состояния отправки
      setIsSubmitting(false);
    }
  };

  return (
    <div className='SendPhoneNumber'>
      <img className='background-image' src='/images/homme.webp' alt='фон' />
      <div className='bg-overlay'>
        <h1>Профессиональный ремонт и выкуп техники в Санкт-Петербурге</h1>
        <div className='form-container'>
          <p>Хотите продать или отремонтировать технику?</p>
          <p>Оставьте свои данные, и мы вам перезвоним</p>
          <form onSubmit={handleSubmit}>
            <input
              name='name'
              type='text'
              placeholder='Имя клиента'
              value={formData.name}
              onChange={handleChange}
              required
            />
            <InputMask
              mask='+7 (999) 999-99-99'
              value={formData.phone}
              onChange={handleChange}
              name='phone'
              type='tel'
              maskChar={null}
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  className={`form-input ${error ? 'error' : ''}`}
                  placeholder='+7 (XXX) XXX-XX-XX'
                  required
                />
              )}
            </InputMask>
            {error && <p className='error-message'>{error}</p>}
            <select
              name='model'
              value={formData.model}
              onChange={handleChange}
              required
            >
              <option value='' disabled>Выберите модель телефона</option>
              {phoneModels.map((model, index) => (
                <option key={index} value={model}>{model}</option>
              ))}
            </select>
            <select
              name='condition'
              value={formData.condition}
              onChange={handleChange}
              required
            >
              <option value='' disabled>Выберите состояние телефона</option>
              <option value='Новый'>Новый</option>
              <option value='Б/У'>Б/У</option>
              <option value='С повреждениями'>С повреждениями</option>
            </select>
            <textarea
              name='description'
              placeholder='Описание'
              value={formData.description}
              onChange={handleChange}
              required
            />
            <select
              name='serviceType'
              value={formData.serviceType}
              onChange={handleChange}
              required
            >
              <option value='' disabled>Выберите тип услуги</option>
              <option value='Продать'>Продать</option>
              <option value='Отремонтировать'>Отремонтировать</option>
              <option value='Выездной ремонт'>Выездной ремонт</option>
            </select>
            {formError && <p className='form-error'>{formError}</p>}
            <button type='submit' disabled={isSubmitting}>
              {isSubmitting ? 'Отправка...' : 'Отправить'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
