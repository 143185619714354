import React from 'react';
import '../scss/Products.scss';

export function Products({ products, backgroundImage }) {
  return (
    <div className='products-section' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className='container mx-auto py-16 px-4'>
        <h2 className='section-title'>
          Принимаем на продажу: какую технику Apple мы скупаем
        </h2>
        <div className='grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
          {products.map((el) => (
            <div key={el.id} className='product-item'>
              <img
                className='product-image'
                src={el.image}
                alt={`Изображение ${el.title}`}
              />
              <div className='overlay'>
                <h3 className='overlay-title'>{el.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
