import React from 'react';
import '../scss/ContactPage.scss';
import { FAQ } from '../components/FAQ';
import { SocialLinks } from '../components/SocialLinks';

export function ContactPage() {
  return (
    <div className="contact-page">
      <header className="contact-header">
        <h1>Контактная информация</h1>
        <p className="contact-subtitle">Мы здесь, чтобы помочь вам.</p>
      </header>

      <section className="contact-details">
        <div className="contact-item">
          <h2>Время работы</h2>
          <p>Ежедневно с 10:00 до 22:00</p>
        </div>
        <div className="contact-item">
          <h2>Телефон</h2>
          <p><a href="tel:+79679794350">+7-967-979-43-50</a></p>
        </div>
        <div className="contact-item">
          <h2>Адрес</h2>
          <p>Санкт-Петербург, Площадь Конституции 3к2</p>
        </div>
      </section>

      <section className="map-container">
        <h2>Как нас найти</h2>
        <div className="map">
          <iframe
            src="https://yandex.ru/map-widget/v1/?ll=30.304960%2C59.850634&mode=search&oid=65315595874&ol=biz&z=15.19"
            frameBorder="0"
            title="Карта расположения AM-Service"
            loading="lazy"
            allowFullScreen
            width="100%"
            height="400"
            style={{ border: 0 }}
          ></iframe>
        </div>
      </section>

      <FAQ />
      <SocialLinks />
    </div>
  );
}
