import React from 'react';
import '../scss/About.scss';
import { FaAppleAlt, FaUsers, FaTools } from 'react-icons/fa';

export const About = () => {
  return (
    <section className="about-section">
      <h1 className="about-title mt-[10%]">О компании Re-Service</h1>
      <div className="icon-grid">
        <div className="icon-card">
          <FaAppleAlt className="icon" />
          <h2 className="subtitle">Наши Продукты</h2>
          <p className="description">
            В Re-Service мы предлагаем только лучшее для ваших устройств. Продажа и ремонт техники, аксессуары и многое другое.
          </p>
        </div>
        <div className="icon-card">
          <FaUsers className="icon" />
          <h2 className="subtitle">Наша Команда</h2>
          <p className="description">
            Наша команда – это профессионалы, которые живут и дышат технологиями. Мы всегда готовы помочь вам.
          </p>
        </div>
        <div className="icon-card">
          <FaTools className="icon" />
          <h2 className="subtitle">Наши Услуги</h2>
          <p className="description">
            Качественный ремонт и обслуживание, быстрые сроки и полная прозрачность. Ваша техника в надёжных руках.
          </p>
        </div>
      </div>
    </section>
  );
};
