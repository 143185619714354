import React from 'react';
import '../scss/SocialLinks.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faTelegram, faInstagram } from '@fortawesome/free-brands-svg-icons';

export function SocialLinks() {
  return (
    <div className="social-links">
      <h2 className="social-title">Связь с нами</h2>
      <p className="social-description">
        Мы всегда рады ответить на ваши вопросы! Свяжитесь с нами через любые из следующих платформ:
      </p>
      <div className="social-icons">
        <a 
          href="https://wa.me/+79679794350" 
          target="_blank" 
          rel="noopener noreferrer" 
          aria-label="WhatsApp"
          className="social-icon whatsapp"
        >
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
        <a 
          href="https://t.me/am_servise" 
          target="_blank" 
          rel="noopener noreferrer" 
          aria-label="Telegram"
          className="social-icon telegram"
        >
          <FontAwesomeIcon icon={faTelegram} />
        </a>
        <a 
          href="https://instagram.com/am_servise" 
          target="_blank" 
          rel="noopener noreferrer" 
          aria-label="Instagram"
          className="social-icon instagram"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </div>
    </div>
  );
}
