import React, { useState } from 'react';
import '../scss/ServiceCard.scss'; // Обновите путь в зависимости от вашего проекта

export const ServiceCard = ({ title, description, icon, details }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDetails = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="service-card">
      <div className="service-header" onClick={toggleDetails}>
        <span className="service-icon">{icon}</span>
        <h3>{title}</h3>
        <button className="toggle-button">{isOpen ? 'Скрыть' : 'Подробнее'}</button>
      </div>
      <div className={`service-details ${isOpen ? 'open' : ''}`}>
        <p>{details}</p>
      </div>
    </div>
  );
};
