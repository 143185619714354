import React from 'react';
import '../scss/AboutService.scss';
import { FaRecycle, FaShoppingCart, FaWrench, FaTruck } from 'react-icons/fa';
import { motion } from 'framer-motion';

export const AboutService = () => {
  return (
    <motion.section className="services"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: 'easeOut' }}
    >
      <h2 className="services-title mt-[5%]">Наши услуги</h2>
      <div className="services-container">
        <div className="service-item">
          <FaRecycle className="service-icon" />
          <h3 className="service-subtitle">Выкуп техники</h3>
          <p className="service-description">
            Быстро и удобно обменяйте вашу технику Apple на деньги.
          </p>
        </div>
        <div className="service-item">
          <FaShoppingCart className="service-icon" />
          <h3 className="service-subtitle">Продажа</h3>
          <p className="service-description">
            Широкий выбор устройств Apple по лучшим ценам.
          </p>
        </div>
        <div className="service-item">
          <FaWrench className="service-icon" />
          <h3 className="service-subtitle">Ремонт</h3>
          <p className="service-description">
            Качественный ремонт всех моделей.
          </p>
        </div>
        <div className="service-item">
          <FaTruck className="service-icon" />
          <h3 className="service-subtitle">Выездной ремонт</h3>
          <p className="service-description">
            Профессиональный ремонт устройств на дому или в офисе.
          </p>
        </div>
      </div>
    </motion.section>
  );
};
