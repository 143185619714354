import React from 'react';
import { FaDollarSign, FaHandshake, FaHandHolding } from 'react-icons/fa';
import '../scss/AboutCompany.scss';

const icons = {
  dollar: FaDollarSign,
  handshake: FaHandshake,
  handHolding: FaHandHolding,
};

export function AboutCompany({ advantages }) {
  return (
    <div className='AboutCompany mt-16'>
      <div className='container mx-auto py-16 px-4'>
        <h2 className='title text-4xl font-bold text-center text-dark-gray mb-14'>
          Почему выбирают нас?
        </h2>
        <div className='description text-lg text-gray-700 max-w-3xl mx-auto'>
          <p>
            Мы — специалисты по покупке и ремонту техники. Если у вас есть старый или неисправный гаджет, который вы хотите продать или отремонтировать, мы готовы помочь. Мы работаем с любыми устройствами, будь то ноутбук, смартфон или планшет.
          </p>
          <p>
            Цифровая техника может стать обузой, когда она выходит из строя или морально устаревает. Наша цель — сделать процесс избавления от ненужной техники простым и выгодным для вас. Мы предоставляем быстрое и удобное решение для продажи или ремонта вашего устройства.
          </p>
          <p>
            Обращаясь к нам, вы получаете не только профессиональный подход, но и возможность заработать на устаревших устройствах. Мы обеспечиваем оперативную обработку заявок и высокое качество услуг.
          </p>
        </div>
      </div>

      <div className='advantages-section py-16 bg-light-gray'>
        <h2 className='section-title text-3xl font-bold text-center text-dark-gray mb-12'>
          Наши преимущества
        </h2>
        <div className='container mx-auto grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-4'>
          {advantages.map((advantage) => {
            const Icon = icons[advantage.icon]; // Получаем компонент иконки
            return (
              <div
                key={advantage.id}
                className='advantage-item bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105'
              >
                <div className='icon-container p-6 flex justify-center items-center'>
                  <Icon className='icon-image text-5xl text-teal-500' />
                </div>
                <div className='p-6 text-center'>
                  <h3 className='text-xl font-semibold text-dark-gray mb-3'>
                    {advantage.title}
                  </h3>
                  <p className='text-gray-600'>
                    {advantage.desc}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
