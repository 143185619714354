import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { App } from './App';
import { BrowserRouter as Router } from 'react-router-dom';

// Создание корневого элемента для рендеринга
const root = ReactDOM.createRoot(document.getElementById('root'));

// Рендеринг приложения с настройкой маршрутизации
root.render(
  <Router>
    <App />
  </Router>
);
